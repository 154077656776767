.customesize {
  /* margin: 100px !important; */
  text-align: left;
}

.customesize .ant-col {
  padding: 8px 50px !important;
}

.customesize .ant-typography {
  font-size: large;
}

.res-aboutus {
  position: absolute;
  left: -25px;
}

@media only screen and (device-width: 1920px) and (device-height: 1080px) {
  /* .res-aboutus {
    position: absolute;
    left: 100px;
  } */

  .customesize {
    text-align: center;
  }
}

@media only screen and (device-width: 768px) and (max-device-width: 1024px) {
  .res-aboutus {
    position: absolute;
    left: 22%;
  }
  .service{
    text-align: center;
  }
}

@media only screen and (device-width: 1024px) and (max-device-width: 1366px) {
  .res-aboutus {
    position: absolute;
    left: 30%;
  }
  .service{
    text-align: center;
  }
}
