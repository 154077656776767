.login-row {
  padding-top: 12%;
  width: 100%;
  height: 100%;
}
/* @media (min-device-width: 411px) {
  .login-row {
    padding-top: 36%;
    width: 100%;
    height: 100%;
  }
} */

@media only screen and (device-width: 200px) and (max-device-width: 375px) and (min-device-width: 411px) {
  .login-row {
    padding-top: 36%;
    width: 100%;
    height: 100%;
  }
}
