@import '~antd/dist/antd.css';
  /* .stickeyFooter {
    color: white !important;
    background: #001529 !important;
    position: fixed;
    height: 4%;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
    margin: auto;
    padding: 10px;
  }
  .stickeyFooter > a {
    text-decoration:none;
    color: white;
  }
  .stickeyFooter > a:link {
    color: white;
  }
  
  .stickeyFooter >a:hover {
    color: white;
  }
  
  .stickeyFooter > a:active {
    color: white;
  } */

  .footer {
    text-align: center;
    position:fixed;
    bottom:0;
    left:0;
    width:100%;
    height: 90px;
    color: white !important;
    background-color: #000000;
    padding-top: 1%;
  }