@import "../../../../node_modules/antd/dist/antd.css";
.headerlogo {
  width: 48px;
  height: 48px;
  margin: 10px 24px 0px 24px;
  float: left;
}
.socialMediaIcon {
  width: 32px;
  height: 32px;
  margin: 16px 0px 8px 16px;  
  float: right;
}
/* .headerlogo {
  width: 48px;
  height: 48px;
  margin: 8px 24px 8px 24px;
  float: left;
}
.ant-layout-header {
  background: #fff;
  padding: 0px;
}
.rightLoginMenu {
  float: right;
}
.SubMenuIcon {
  vertical-align: initial !important;
}
.socialMediaIcon {
  width: 32px;
  height: 32px;
  margin: 16px 0px 8px 16px;  
  float: right;
}
.modified-item:hover {
  border-bottom: 0px solid transparent !important;
  color: inherit !important;
}

@media screen and (device-width: 375px) and (device-height: 667px) and (-webkit-device-pixel-ratio: 2) {
  .headerlogo {
    width: 48px;
    height: 48px;
    margin: 8px 24px 8px 24px;
    float: left;
  }
  .rightLoginMenu {
    float: right;
  }
  .SubMenuIcon {
    vertical-align: initial !important;
  }
  .socialMediaIcon {
    width: 20px;
    height: 20px;
    margin: 16px 0px 8px 16px;  
    float: right;
  }
}

@media only screen and (min-device-width: 200px) and (max-device-width: 375px) {
  .headerlogo {
    width: 48px;
    height: 48px;
    margin: 8px 8px 8px 8px;
    float: left;
  }
  .ant-layout-header {
    background: #fff;
    padding: 0px;
  }
  .socialMediaIcon {
    width: 16px;
    height: 16px;
    margin: 16px 0px 8px 16px;  
    float: right;
  }
}
@media only screen and (min-device-width: 400px) and (max-device-width: 750px) {
  .headerlogo {
    width: 48px;
    height: 48px;
    margin: 8px;    
  }
  .ant-layout-header {
    background: #fff;
    padding: 0px;
  }
  .SubMenuIcon {
    vertical-align: initial !important;
  }
  .socialMediaIcon {
    width: 48px;
    height: 48px;
    margin: 16px 0px 8px 16px;  
    float: right;
  }
} */

.menuBar {
  height: 60px;
  padding: 0 10px;
  border-bottom: solid 1px #e8e8e8;
  overflow: auto;
  box-shadow: 0 0 30px #f3f1f1;
}

/* .logo {
  width: 150px;
  float: left;
} */



.menuCon {
  width: calc(100% - 150px);
  float: left;
  padding: 11px 0 0 0;
}

.menuCon .ant-menu-item {
  padding: 0px 5px;
}

.menuCon .ant-menu-submenu-title {
  padding: 10px 20px;
}

.menuCon .ant-menu-item a,
.menuCon .ant-menu-submenu-title a {
  padding: 10px 0px;
}

.menuCon .ant-menu-horizontal {
  border-bottom: none;
}

.menuCon .leftMenu {
  float: left;
}

.menuCon .rightMenu {
  float: right;
}

.barsMenu {
  float: right;
  height: 32px;
  padding: 6px;
  margin-top: 8px;
  display: none;
  background: none;
}

.barsBtn {
  display: block;
  width: 20px;
  height: 2px;
  position: relative;
}

.barsBtn:after,
.barsBtn:before {
  content: attr(x);
  width: 20px;
  position: absolute;
  top: -6px;
  left: 0;
  height: 2px;
  background: #1890ff;
}

.barsBtn:after {
  top: auto;
  bottom: -6px;
}

.ant-drawer-body {
  padding: 0;
}

.barsMenu>span {
  display: block;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item,
.ant-drawer-body .ant-menu-horizontal>.ant-menu-submenu {
  display: inline-block;
  width: 100%;
}

.ant-drawer-body .ant-menu-horizontal {
  border-bottom: none;
}

.ant-drawer-body .ant-menu-horizontal>.ant-menu-item:hover {
  border-bottom-color: transparent;
}

@media (max-width: 767px) {
  .barsMenu {
    display: inline-block;
  }

  .leftMenu,
  .rightMenu {
    display: none;
  }

  .logo a {
    margin-left: -20px;
  }

  .menuCon .ant-menu-item,
  .menuCon .ant-menu-submenu-title {
    padding: 1px 20px;
  }

  .logo a {
    padding: 10px 20px;
  }
}