.trigger {
    font-size: 18px;
    line-height: 64px;
    padding: 0 24px;
    cursor: pointer;
    transition: color 0.3s;
  }
  
.trigger:hover {
    color: #1890ff;
  }
  
  .logo {
    height: 32px;
    margin: 16px;
    text-decoration: none;    
  }

  stickeyDashboardFooterText {
    float: right;     
    bottom: 0;
    width: 100%;
    text-align: right;
    color: white !important;
  }
  .site-layout .site-layout-background {
    background: #fff;

  }